import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../state/Store'

const winston = require('winston');
const { combine, timestamp, json } = winston.format;

const logger = winston.createLogger({
  level: 'debug',
  format: combine(timestamp(), json()),
  transports: [new winston.transports.Console()],
});

logger.debug('Hello World logs');

const airtable = require('airtable');
const AirtableApiKey = process.env.REACT_APP_AIRTABLE_API;

if (AirtableApiKey) {
  logger.debug('Airtable API key found', AirtableApiKey);
} else {
  logger.error('No Airtable API key found');
}

const Airtable = new airtable({
  endpointUrl: 'https://api.airtable.com',
  apiKey: AirtableApiKey,
});

const base = Airtable.base('appmMPRUHmUW2uzZF');

async function validateAuthentication() {
  try {
    const firstPage = await base('Players').select().firstPage(); // Replace "Leagues" with your actual table name
    console.debug('Authentication successful!');
  } catch (error) {
    console.error("Authentication failed:", error.message);
  }
}

validateAuthentication();

export const useAirtable = (sheet, options, action) => {
  const [state, dispatch] = useContext(Context);
  const { property, type } = action;
  const stateProperty = state && property && state[property];

  useEffect(() => {
    const fetchAirtableData = () => {
      const data = []
      try {
        base(sheet).select(options).eachPage(function page(records, fetchNextPage) { 
        records.forEach((record) => {
          if (Object.values(records).find(v => Boolean(v))) {
            data.push({ ...record.fields })
          }
        });
        fetchNextPage();
  
      }, function done(err) {
          if (err) { console.error(err); return; }
          dispatch({ type: type ?? `SET_${sheet.toUpperCase()}`, payload: { property, data, fetched: true } })
      });
      } catch (error) {
        dispatch({type: 'SET_ERROR', payload: error })
      }
    }

    if (!stateProperty?.fetched) {
      fetchAirtableData()
    }
  }, [])
}

export const useAirtableDirect = (sheet, options, action) => {
  const [state, dispatch] = useContext(Context);
  const  { property, type } = action;
  const stateProperty = state && property && state[property];

  useEffect(() => {
    const fetchAirtableData = () => {
      const data = []
      try {
        base(sheet).select(options).eachPage(function page(records, fetchNextPage) { 
        records.forEach((record) => {
          if (Object.values(records).find(v => Boolean(v))) {
            data.push({ ...record.fields })
          }
        });
        fetchNextPage();
  
      }, function done(err) {
          if (err) { console.error(err); return; }
          dispatch({ type: type ?? `SET_${sheet.toUpperCase()}`, payload: { property, data, fetched: true } })
      });
      } catch (error) {
        dispatch({type: 'SET_ERROR', payload: error })
      }
    }

    if (!stateProperty?.fetched) {
      fetchAirtableData()
    }
  }, [])
}