import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import ScrollTop from './ScrollTop'
import Home from './Home'
import { RegisterTeam, LeagueList, FreeAgent } from './Join'
import { SponsorLeague, Fundraising, PrivateEvents } from './Host'
import { FAQs, Rules, Schedule, Stats } from './Dartball'
import { AboutUs, ContactUs } from './AboutUs'
import { useAirtable } from '../hooks'
import './App.less';

const Routes = () => {
  useAirtable('Leagues', { view: 'Open Leagues', fields: ['Name','Logo','Location','Address','Spots Remaining', 'Notes'] }, { property: 'leagues' })
  useAirtable('Content', { view: 'All Content', fields: ['Title','Description','Content Type', 'Type'] }, { property: 'content' })

  return (
    <>
      <ScrollTop />
      <Switch>
        <Route path="/frequently-asked-questions">
          <FAQs />
        </Route>
        <Route path="/league-rules">
          <Rules />
        </Route>
        <Route path="/schedule">
          <Schedule />
        </Route>
        <Route path="/standings">
          <Stats />
        </Route>
        <Route path="/find-a-league">
          <LeagueList />
        </Route>
        <Route path="/register-team">
          <RegisterTeam />
        </Route>
        <Route path="/free-agent">
          <FreeAgent />
        </Route>
        <Route path="/sponsor-league">
          <SponsorLeague />
        </Route>
        <Route path="/fundraising">
          <Fundraising />
        </Route>
        <Route path="/private-events">
          <PrivateEvents />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </>
  )
}

export default Routes;