import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'


const initialState = {
    content: {
        data: [],
        fetched: false,
    },
    leagues: {
        data: [],
        fetched: false,
    },
    events: {
        data: [],
        fetched: false,
    },
    standings: {
        leagues: {},
        fetched: false,
    },
    stats: {
        data: {},
        fetched: false,
    },
    photos: {
        data: [],
        fetched: false,
    },
    error: null
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;
