import { groupBy } from 'lodash';

const Reducer = (state, action) => {
  const { property } = action.payload
  switch (action.type) {
    case 'SET_STANDINGS':
    const leagues = groupBy(action.payload.data, 'League Name')
    return {
        ...state,
        [property]: { leagues }
    };
    case 'SET_ERROR':
        console.error("ERROR: ", action.payload)
        return {
            ...state,
            error: action.payload
        };
    default:
      return {
          ...state,
          [property]: action.payload
      };  
  }
};

export default Reducer;
