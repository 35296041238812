import React, { useContext } from 'react';
import {
  Button,
  Result,
  Row,
  Col,
  Divider,
} from 'antd';
import {
  Link,
} from "react-router-dom";
import './App.less';
import dartball_glow from '../assets/Boards.jpg'
import dartball_play from '../assets/playDartball.jpg'
import dartball_garynate from '../assets/GaryNate.jpg'

const Home = () => {
  return (
    <div className="home">
      <div className="home-hero" style={{ backgroundImage: `url(${dartball_glow})`}}>
        <div className="hero-title">Underhanded Events</div>
        <div><small>presents</small></div>
        <div className="hero-subtitle">Dartball</div>
      </div>
      <div className="home-content">
        <Row gutter={[0, 0]}>
          <Col span={24} lg={12}>
          <div className="iframe-container">
            <iframe  src="https://player.vimeo.com/video/547745317" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" autoPlay allowFullScreen controls={false} ></iframe>
          </div>
          </Col>
          <Col span={24} lg={12}>
            <div className="home-subcontent">
              The newest old way to affordably connect, compete, socialize and have FUN! 
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={24} lg={12}>
            <Result
              status="success"
              icon={<img style={{ width: '100%', height: 'auto' }} src={dartball_garynate} />}
              title="Host Dartball"
              subTitle="Interested in hosting Dartball at your brewery, event center or other location?"
              extra={[
                <Button key="sponsor" type="link"><Link to="/sponsor-league">Sponsor a League</Link></Button>,
                <Button key="events" type="link"><Link to="/fundraising">Fundraising Events</Link></Button>,
                <Button key="private" type="link"><Link to="/private-events">Private Events</Link></Button>
                ]}
            />
          </Col>
          <Col span={24} lg={12}>
            <Result
              status="success"
              icon={<img style={{ width: '100%', height: 'auto' }} src={dartball_play} />}
              title="Play Dartball"
              subTitle="Ready to start playing? Find a league near you and register a team or as a free agent"
              extra={[
                <Button key="find" type="link"><Link to="/find-a-league">Find a League</Link></Button>,
                <Button key="register" type="link"><Link to="/register-team">Register Team</Link></Button>,
                <Button key="freeAgent" type="link"><Link to="/free-agent">Join as Free Agent</Link></Button>
                ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Home;