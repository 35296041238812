import React, { useState, useContext } from 'react';
import {
  Table,
  Tabs,
  Typography
} from 'antd';
import {
  Link,
  useLocation,
} from "react-router-dom";
import './App.less';
import { Context } from '../state/Store'

const { Title } = Typography;

const columns = [
  {
    title: '',
    dataIndex: 'Logo',
    key: 'logo',
    render: imgs => {
      const img = imgs[0];
      return <img src={img.thumbnails.large.url} />
    },
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'name',
  },
  {
    title: 'Location',
    dataIndex: 'Location',
    key: 'location',
    responsive: ['md'],
    render: link => {
      const splitLink  = link.split('](');
      const name = splitLink[0].slice(1)
      const url = splitLink[1].slice(0, -2)
      return <a href={url} target="_blank">{name}</a>
    },
  },
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'address',
    responsive: ['md'],
  },
  {
    title: 'Spots Remaining',
    dataIndex: 'Spots Remaining',
    key: 'spotsRemaining',
    render: (text, record) => {
      return (<div style={{ paddingLeft: '30%' }}><strong style={{ fontSize: 24 }}>{text}</strong><br /><Link to={{
          pathname: "/register-team",
          state: { fromLeague: record.Name }
        }}>Join</Link></div>)
    },
  },
]


export const LeagueList = () => {
  const [state] = useContext(Context);
  const { leagues: { data } } = state;

  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          FIND A LEAGUE
        </Title>
      </div>
      <div className="page-title">
        {data.length > 0 ? 'Current list of open leagues you can join and their locations to find one near you.' : 'All leagues are full at this moment. Check back later!'}
      </div>
      <div className="page-title">
        <Table
          rowKey={record => record.Name}
          columns={columns}
          dataSource={data}
          pagination={false}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.Notes}</p>,
            rowExpandable: record => Boolean(record.Notes),
            defaultExpandAllRows: true
          }}
        />
      </div>
    </section>
  )
}

export const RegisterTeam = () => {
  const location = useLocation()
  const { state } = location

  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          REGISTER TEAM
        </Title>
      </div>
      <div className="page-title">
       Submit your team name, league to join, the name and email of the team captain and if your team is willing to let free agents join. League fee for team registration is $400.00 for 8 weeks which includes 2 games a week plus playoff tournament.
      </div>
      <div className="page-title">
        After submittal, we will reach out to the captain to collect payment and finalize registration.
      </div>
      {/* Forgotten+Star+League+-+Tuesday+Night */}
      <iframe className="airtable-embed airtable-dynamic-height" src={`https://airtable.com/embed/shrwfKT5U1ApJDR7h?backgroundColor=orange${state?.fromLeague ? `&prefill_League=${state?.fromLeague}` : ''}`} frameBorder="0" width="100%" height="1102" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>
    </section>
  )
}

export const FreeAgent = () => {
  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          JOIN AS FREE AGENT
        </Title>
      </div>
      <div className="page-title">
        Don't have a team but still want to play? No problem, let us find you a team. Pick a league to join and we will get back to you as soon as we find a team to pair you up with. Cost to join as a free agent is $75.00.
      </div>
      <iframe className="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrCXTFYCplocfheo?backgroundColor=orange" frameBorder="0" width="100%" height="952" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>
    </section>
  )
}
