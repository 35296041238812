import React, { useContext, useMemo } from 'react';
import {
  Avatar,
  Row,
  Col,
  List,
  Typography,
} from 'antd';
import './App.less';
import {
  useLocation,
  Link,
} from "react-router-dom";
import { Context } from '../state/Store'
import dartballLogo from '../assets/dartballLogoFull.png'

const { Title } = Typography;

export const SponsorLeague = () => {
  const [state] = useContext(Context);
  const { content: { data } } = state;
  const dataSource = useMemo(() => data.filter(d => d['Content Type'] === 'Hosting'), [data]);

  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          SPONSOR A DARTBALL LEAGUE
        </Title>
      </div>
      <div className="page-title">
      <Row gutter={16}>
      <Col span={24} lg={12}>
        <List
          size="large"
          header={<div>What Underhanded Events Provides</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Provided')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
        />
        <div className="page-title">
        To learn more and get things rolling please complete the form below or <Link to ="/contact">contact us</Link>
      </div>
      <iframe className="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrmIS2V1wMASIsir?backgroundColor=orange" frameBorder="0" width="100%" height="1077" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>
      </Col>
      <Col span={24} lg={12}>
        <List
          size="large"
          header={<div>What You Provide</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Provide')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        </Col>
    </Row>
      </div>
      </section>
  )
}

export const Fundraising = () => {
  const [state] = useContext(Context);
  const { content: { data } } = state;
  const dataSource = useMemo(() => data.filter(d => d['Content Type'] === 'Fundraising'), [data]);

  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          FUNDRAISER EVENTS
        </Title>
      </div>
      <div className="page-title">
      <Row gutter={16}>
      <Col span={24} lg={12}>
        <List
          size="large"
          header={<div>Big positives having Underhanded Events Dartball for your fundraising efforts</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Positives')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
        />
        <div className="page-title">
        To learn more and get things rolling please complete the form below or <Link to ="/contact">contact us</Link>
      </div>
      <iframe className="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrHnthrbAW5B0M14?backgroundColor=orange" frameBorder="0" width="100%" height="1487" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>
      </Col>
      <Col span={24} lg={12}>
        <div className="page-title">
          Other ways to make money for your organization using Underhanded Events
        </div>
        <List
          size="large"
          header={<div>Host Your Own Underhanded Events Dartball Tournament</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Tournament')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        <div className="page-title">
        <List
          size="large"
          header={<div>Golf Tournament</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Golf')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        </div>
        <div className="page-title">
        <List
          size="large"
          header={<div>Private League</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Private')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        </div>
      </Col>
    </Row>
      </div>
      </section>
  )
}

export const PrivateEvents = () => {
  const [state] = useContext(Context);
  const { content: { data } } = state;
  const dataSource = useMemo(() => data.filter(d => d['Content Type'] === 'Fundraising'), [data]);

  return (
    <section className="page">
      <div className="page-title">
        <Title level={2}>
          PRIVATE EVENTS
        </Title>
      </div>
      <div className="page-title">
      <Row gutter={16}>
      <Col span={24} lg={12}>
      <div className="page-title">
        To learn more and get things rolling please complete the form below or <Link to ="/contact">contact us</Link>
      </div>
      <iframe className="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrHTb3SuTteb4XbD?backgroundColor=orange" frameBorder="0" width="100%" height="1316" style= {{ background: 'transparent', border: '1px solid #ccc' }} ></iframe>
      </Col>
      <Col span={24} lg={12}>
      <div className="page-title">
          Host a fun and unique experience for your next private event using Underhanded Events
        </div>
        <List
          size="large"
          header={<div>Host Your Own Underhanded Events Dartball Tournament</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Tournament')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        <div className="page-title">
        <List
          size="large"
          header={<div>Golf Tournament</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Golf')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        </div>
        <div className="page-title">
        <List
          size="large"
          header={<div>Private League</div>}
          bordered
          style={{ background: '#fff' }}
          dataSource={dataSource.filter(i => i.Type === 'Private')}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={dartballLogo} />}
                title={item?.Title ?? ''}
                description={item.Description}
              />
            </List.Item>
          )}
          />
        </div>
        </Col>
    </Row>
      </div>
      </section>
  )
}
